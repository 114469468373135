






























































import { prefix } from '@/config/global';
import { Vue, Component, Watch } from 'vue-property-decorator';
import rankFilter from '@/components/rankFilter/index.vue';
import rankTable from '@/components/rankTable/index.vue';
import {
  getMapCenter, initMap, drawMall, clearMall, zoomUp, zoomDown, fillColorPage, drawLabel,
} from '@/components/map/map';
import { Grid } from '@/components/map/grid';
import gradientCard from '@/components/GradientCard/index.vue';
import spinCard from '@/components/SpinCard/index.vue';
import { createPopLevelArr, getContainer, getDateByDuration } from '@/components';
import competeRegionInfo from '@/components/competeRegionInfo/index.vue';
import { getCompetitionIntensityData } from '@/services/api';
import { stackBarOption } from '@/utils/echarts/echarts';

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */

@Component({
  components: {
    spinCard,
    rankFilter,
    rankTable,
    gradientCard,
    competeRegionInfo,
  },
})


export default class Regional extends Vue {
  prefix: string = prefix;
  spinShow = true;
  slotEcharts = true;
  text = '1平方公里';
  mallMarker!: any;
  tableWh = 600;
  table: any= [];
  res: any;
  data: any= [];
  gridData: any= [];
  map: any;
  duration = 'xm';
  mall_id = this.$store.state.mall.mallId;
  date = this.$store.state.mall.date;
  dates = this.$store.state.mall.dates;
  boundary = this.$store.state.mall.boundary;
  center = this.$store.state.mall.center;
  params!: any;
  rowActive = 0;
  initZom = 12;
  step = 0.01;
  max = 100;
  min = 0;
  mColorAry = ['rgba(162, 176, 228, 0.8)', 'rgba(132, 150, 220, 0.8)', 'rgba(101, 124, 211, 0.8)', 'rgba(39, 71, 193, 0.8)'];
  nColorAry = ['rgba(146, 231, 231, 0.8)', 'rgba(109, 223, 222, 0.8)', 'rgba(73, 215, 214, 0.8)', 'rgba(2, 111, 110, 0.8)'];
  fairColorAry = ['rgba(158,158,158, 0.9)'];
  gridColor = ['rgba(43,50,153, .7)', 'rgba(46,63,176, .7)', 'rgba(50,85,199, .7)', 'rgba(49,104,222, 7)', 'rgba(43,115,224, .7)', 'rgba(41,132,230, .7)', 'rgba(41,145,230, .7)', 'rgba(46,178,255, .7)', 'rgba(74,195,255, .7)'];
  gridColor2 = ['rgba(255,255,255,.1)'];
  gradientWidth = 22;
  gradientList: any= [
  ];
  gradientValue = [
    {
      id: 1,
      num: '主场占优',
    },
    {
      id: 2,
      num: '持平',
    },
    {
      id: 3,
      num: '竞品占优',
    },
  ];
  grid: any;
  type = 0;
  singleColor = 'blue';
  titleDisplay = false;
  gridColorObj1 = {
    color: this.gridColor,
    top0Color: '',
    top1Color: '',
    strokeStyle: 'rgba(255,255,255,.6)',
    lineWidth: 1,
    textColor: '#ffffff',
    mColor: this.mColorAry,
    nColor: this.nColorAry,
    mExclusive: 'rgba(39, 71, 193, 0.8)',
    nExclusive: 'rgba(2, 111, 110, 0.8)',
    fairColorAry: this.fairColorAry[0],
    fairColor: this.fairColorAry[0],
  };
  color = 'rgba(255,255,255,.1)';
  gridColorObj = this.gridColorObj1;
  mapHeight = '0';
  gridType = 0;
  gridClickType = 0;
  compete_mall_id = 0;
  competeGridData: any = [];
  MGridData: any = [];
  radioType = 1;
  radioText = '全部区域';
  tipInfoStatus = true;
  wrapModel = false;
  stackBarOption: any = {};
  stackBarXData: any = [];
  stackBarDataOne: any = [];
  stackBarDataTwo: any = [];
  eWh = 0;
  eHg = 0;
  nName = '';
  mallName = '';
  gridInfoStyle = {
    zIndex: 12,
    transform: 'translateY(-126%) translateX(-48%)',
    backgroundColor: '#FFFFFF',
    borderRadius: '3px',
    color: '#030227',
    border: 'none',
  };
  durationText = '日';
  compareDuration = '较昨日';
  gridLabel: any;
  $jQ: any;
  time: any;
  nMallMarker: any;
  closePath = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAAXNSR0IArs4c6QAAAadJREFUOBGtlL1qAkEUhXdm2YX8aGNhEX2AKAQFH8AHsEybKkUgDxRIkSptOu3jAwhKQPMAGtDCRiGSXXbX+5lcGRaFBDLg3p1zzz17f2Y0Xm5lWWYmk0lQLBb9IAjscrk0pVIpi+M4Xa1WSa1Wi40xmRtm3M1gMAgqlUpIoIu77wjOZrOo1WrFiu/J4/E4FJDfb1dUr9cjyJYHGYgJu93ua6/Xe1iv1zscn7vA8cMTPPyJ8yw9oATIUut7kiQ3/X7/KS/EHhw/PPjEEW/cMpSYpum1tfal3W7fFgqF9BiOkKzITKfTE+m6/733vHxAo9G4G41Gj3lh5csUEzOfz8/y03CFJPUPSfnCzUwFsEyLcs5dUN8lw3A4HL4hgFCz2byqVqu7aShHrUVJN2rJhBJUAMseXDlqibecRAWwbimUQAZYenJoasRbjrKK5AWYDiVgjwkRbyRVs1gsTmkuB4lzcKiJ7gd833/udDr3lFIulz+tNG13F8hGBC8h6PnQDLGcF3D88MC4Q8T/391Blcu02Wy+Dk0Lvy788PTyge8zURI9+uv/yRZLrluBx2EoJQAAAABJRU5ErkJggg==';
  competeTip = Vue.prototype.$GLOBAL.competeTip;
  regionInfo = {
    m_obj: { m_sum: 0, area: 0, n_sum: 0 },
    n_obj: { m_sum: 0, area: 0, n_sum: 0 },
    m_n_obj: { m_sum: 0, area: 0, n_sum: 0 },
    m_un_obj: { m_sum: 0, area: 0 },
    n_un_obj: { n_sum: 0, area: 0 },
  };
  regionInfo2 = {
    m_obj: { m_sum: 0, area: 0, n_sum: 0 },
    n_obj: { m_sum: 0, area: 0, n_sum: 0 },
    m_n_obj: { m_sum: 0, area: 0, n_sum: 0 },
    m_un_obj: { m_sum: 0, area: 0 },
    n_un_obj: { n_sum: 0, area: 0 },
  };
  allData = {
    data1: {},
    data2: {},
    data3: {},
    data4: {},
    data5: {},
    data6: {},
  };
  title = '竞争烈度区域分析';
  $beaconAction: any;
  created(): void{
    this.gradientList = [];
    const colorAry = [...this.mColorAry, ...this.fairColorAry, ...this.nColorAry.reverse()];
    colorAry.forEach((item: any, index: number) => {
      const obj = {
        id: index + 1,
        color: item,
        width: this.gradientWidth,
      };
      this.gradientList.push(obj);
    });
  }
  mounted(): void {
    this.boundary = this.$store.state.mall.boundary;
    this.center = this.$store.state.mall.center;
    this.mapHeight = `${getContainer()}px`;
    this.map = initMap('map-container', this.initZom, this.center);
    this.gridColorObj = this.gridColorObj1;
    this.gridType = 0;
    this.pageHandlers();
  }

  pageHandlers(): void{
    this.$stores.global.setDownAble(false); // 数据全部加载完全后才允许下载
    this.spinShow = true;
    setTimeout(() => {
      this.pageHandler(this.gridType, this.gridColorObj);
    }, 0);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async pageHandler(type: number, gridColorObj?: any): Promise<void> {
    this.closeClickHandler();
    const { mall } = this.$store.state;
    const { mallId, mallName, boundary, center, duration, date, dates, competedModelObj,
      durationText, compareDuration } = mall;
    const { nMallId, nName, nCenterGcj } = competedModelObj;
    this.mall_id = mallId;
    this.mallName = mallName;
    this.boundary = boundary;
    this.center = center;
    this.duration = duration;
    this.date = date;
    this.dates = dates;
    this.compete_mall_id = nMallId;
    this.nName = nName;
    this.durationText = durationText;
    this.compareDuration = compareDuration;
    // this.durationText = this.durationTextObj[duration];

    if (this.center) {
      const center = getMapCenter(this.center);
      this.map.setCenter(center);
      clearMall(this.mallMarker);
      this.mallMarker = drawMall(this.map, center);
    }
    if (nCenterGcj) {
      const center = getMapCenter(nCenterGcj);
      clearMall(this.nMallMarker);
      this.nMallMarker = drawMall(this.map, center, 1);
    }
    this.params = {
      m_mall_id: this.mall_id,
      n_mall_id: this.compete_mall_id,
      date: this.date,
      duration: this.duration,
      type: 1,
      property: 1,
      com_type: 1,
    };
    const res = await getCompetitionIntensityData(this.params);
    if (res.status !== 0) {
      this.spinShow = false;
      return;
    }
    const { data, obj } = res;
    let { region_info: regionInfo } = res;
    const date2 = getDateByDuration(this.duration, this.date);
    const params2 = {
      m_mall_id: this.mall_id,
      n_mall_id: this.compete_mall_id,
      date: date2,
      duration: this.duration,
      type: 1,
      property: 1,
      com_type: 1,
    };
    const res2 = await getCompetitionIntensityData(params2, false);
    let { region_info: regionInfo2 } = res2;
    let objs = obj;
    if (!obj) {
      objs = { m_max: 0, m_min: 0, n_max: 0, n_min: 0 };
    }
    const { m_max: mMax, n_max: nMax } = objs;

    const regionInfos = {
      m_n_obj: { m_sum: 0, area: 0, n_sum: 0 },
      m_obj: { m_sum: 0, area: 0, n_sum: 0 },
      m_un_obj: { m_sum: 0, area: 0 },
      n_obj: { m_sum: 0, area: 0, n_sum: 0 },
      n_un_obj: { n_sum: 0, area: 0 },
    };
    regionInfo = regionInfo ? regionInfo : regionInfos;
    regionInfo2 = regionInfo2 ? regionInfo2 : regionInfos;
    this.regionInfo = regionInfo;
    this.regionInfo2 = regionInfo2;
    const mOptions = { max: mMax, color: gridColorObj.mColor, weightType: 1 };
    const mSectionAry = createPopLevelArr(mOptions);
    const nOptions = { max: nMax, color: gridColorObj.nColor, weightType: 1 };
    const nSectionAry = createPopLevelArr(nOptions);
    if (data.length > 0) {
      data.sort((x: any, y: any) => y.m_num - x.m_num);
    }
    const stackBarXData: number[] = [];
    const stackBarDataOne: number[] = [];
    const stackBarDataTwo: number[] = [];
    data.forEach((item: any, index: number) => {
      const { type, m_num: mNum, n_num: nNum } = item;
      let color = '';
      let num = '';
      const rank = index + 1;
      if (type === 2) {
        color = fillColorPage(mNum, mSectionAry);
        num = mNum;
      } else if (type === 3) {
        color = fillColorPage(nNum, nSectionAry);
        num = nNum;
      } else if (type === 4) {
        color = gridColorObj.fairColor;
        num = mNum;
      } else if (type === 5) {
        color = gridColorObj.mExclusive;
        num = mNum;
      } else if (type === 6) {
        color = gridColorObj.nExclusive;
        num = nNum;
      } else {
        color = gridColorObj.mExclusive;
        num = mNum;
      }
      data[index].color = color;
      data[index].num = num;
      data[index].rank = rank;

      stackBarXData.push(rank);
      stackBarDataOne.push(mNum);
      stackBarDataTwo.push(nNum);
    });
    this.data = data;
    this.gridData = data;
    this.stackBarXData = stackBarXData;
    this.stackBarDataOne = stackBarDataOne;
    this.stackBarDataTwo = stackBarDataTwo;
    const item = {
      value: this.radioType,
      label: this.radioText,
    };
    this.radioChangeHandler(item);
    this.spinShow = false;
    this.$stores.global.setDownAble(true); // 数据全部加载完全后才允许下载
  }

  getGridData(): any {
    const date = this.dates;
    const gridData1: any = [];
    const gridData2: any = [];
    const gridData3: any = [];
    const gridData4: any = [];
    const gridData5: any = [];
    this.data.forEach((item: any) => {
      const { m_num, n_num, m_rank, n_rank, type } = item;
      const obj = {
        date,
        name: '主场客流(人数)',
        num: m_num,
        rank: m_rank,
        types: '',
      };
      const obj1 = {
        date,
        name: '竞品客流(人数)',
        num: n_num,
        rank: n_rank,
        types: '',
      };
      if (type === 2) {
        const types = '主场占优区域';
        obj.types = types;
        obj1.types = types;
        gridData1.push(obj, obj1);
      } else if (type === 3) {
        const types = '竞品占优区域';
        obj.types = types;
        obj1.types = types;
        gridData2.push(obj, obj1);
      } else if (type === 4) {
        const types = '双方持平区域';
        obj.types = types;
        obj1.types = types;
        gridData3.push(obj, obj1);
      } else if (type === 5) {
        const types = '主场独占区域';
        obj.types = types;
        obj1.types = types;
        gridData4.push(obj);
      } else if (type === 6) {
        const types = '竞品独占区域';
        obj.types = types;
        obj1.types = types;
        gridData5.push(obj1);
      }
    });
    return {
      gridData1,
      gridData2,
      gridData3,
      gridData4,
      gridData5,
    };
  }

  processExcelData(): void{
    const date = this.dates;
    const { competeRegion } = this.$refs;
    const { mNPercent, mPercent, nPercent, mUnPercent, nUnPercent,
      mObj, nObj, mNObj, mUnObj, nUnObj } = (competeRegion as any);
      // 主场占优区域,竞品占优区域,双方持平区域,主场独占区域,竞品独占区域
    this.allData.data1 = {
      sheetName: '竞争烈度概览',
      sheetMultiHeader: [
        ['数据项:竞争烈度-竞品区域分析-总体概览'],
      ],
      sheetHeader: [
        this.durationText,
        '区域类型',
        '数据项',
        '数据值',
        this.compareDuration,
      ],
      sheetFilter: ['date', 'type', 'name', 'value', 'compare'],
      sheetData: [
        {
          date,
          type: '主场占优区域',
          name: '主场占优格子面积(平方公里)',
          value: mObj.area,
          compare: `${mPercent}%`,
        },
        {
          date,
          type: '主场占优区域',
          name: '主场客流(人数)',
          value: mObj.m_sum,
          compare: '',
        },
        {
          date,
          type: '主场占优区域',
          name: '竞品客流(人数)',
          value: mObj.n_sum,
          compare: '',
        },
        {
          date,
          type: '竞品占优区域',
          name: '竞品占优格子面积(平方公里)',
          value: nObj.area,
          compare: `${nPercent}%`,
        },
        {
          date,
          type: '竞品占优区域',
          name: '主场客流(人数)',
          value: nObj.m_sum,
          compare: '',
        },
        {
          date,
          type: '竞品占优区域',
          name: '竞品客流(人数)',
          value: nObj.n_sum,
          compare: '',
        },
        {
          date,
          type: '主场独占区域',
          name: '主场独占格子面积(平方公里)',
          value: mUnObj.area,
          compare: `${mUnPercent}%`,
        },
        {
          date,
          type: '主场独占区域',
          name: '主场客流(人数)',
          value: mUnObj.m_sum,
          compare: '',
        },
        {
          date,
          type: '竞品独占区域',
          name: '竞品独占面积(平方公里)',
          value: nUnObj.area,
          compare: `${nUnPercent}%`,
        },
        {
          date,
          type: '竞品独占区域',
          name: '竞品客流(人数)',
          value: nUnObj.n_sum,
          compare: '',
        },
        {
          date,
          type: '持平区域',
          name: '持平格子面积(平方公里)',
          value: mNObj.area,
          compare: `${mNPercent}%`,
        },
        {
          date,
          type: '持平区域',
          name: '持平格子客流(人数)',
          value: mNObj.m_sum,
          compare: '',
        },
      ],
    };
    const { gridData1, gridData2, gridData3, gridData4, gridData5 } = this.getGridData();
    // console.log(data1);
    const sheetFilters = ['date', 'types', 'name', 'rank', 'num'];
    this.allData.data2 = {
      sheetName: '主场占优区域',
      sheetMultiHeader: [
        ['数据项:竞争烈度-竞品区域分析-主场占优区域'],
      ],
      sheetHeader: [
        this.durationText,
        '区域类型',
        '数据项',
        '排名',
        '数据值',
      ],
      sheetFilter: sheetFilters,
      sheetData: gridData1,
    };
    this.allData.data3 = {
      sheetName: '竞品占优区域',
      sheetMultiHeader: [
        ['数据项:竞争烈度-竞品区域分析-竞品占优区域'],
      ],
      sheetHeader: [
        this.durationText,
        '区域类型',
        '数据项',
        '排名',
        '数据值',
      ],
      sheetFilter: sheetFilters,
      sheetData: gridData2,
    };
    this.allData.data4 = {
      sheetName: '双方持平区域',
      sheetMultiHeader: [
        ['数据项:竞争烈度-竞品区域分析-双方持平区域'],
      ],
      sheetHeader: [
        this.durationText,
        '区域类型',
        '数据项',
        '排名',
        '数据值',
      ],
      sheetFilter: sheetFilters,
      sheetData: gridData3,
    };
    this.allData.data5 = {
      sheetName: '主场独占区域',
      sheetMultiHeader: [
        ['数据项:竞争烈度-竞品区域分析-主场独占区域'],
      ],
      sheetHeader: [
        this.durationText,
        '区域类型',
        '数据项',
        '排名',
        '数据值',
      ],
      sheetFilter: sheetFilters,
      sheetData: gridData4,
    };
    this.allData.data6 = {
      sheetName: '竞品独占区域',
      sheetMultiHeader: [
        ['数据项:竞争烈度-竞品区域分析-竞品独占区域'],
      ],
      sheetHeader: [
        this.durationText,
        '区域类型',
        '数据项',
        '排名',
        '数据值',
      ],
      sheetFilter: sheetFilters,
      sheetData: gridData5,
    };
  }
  tipInfoClickHandler(): void{
    this.wrapModel = true;
    this.stackBarOption = stackBarOption(this.stackBarXData, this.stackBarDataOne, this.stackBarDataTwo, this.mallName, this.nName, '', this.radioType);
    this.buriedPointHandler('Passenger_flow_comparison_detail__click');
  }

  drawGrid(gridColorObj: any): void{
    this.grid = new (Grid as any)({
      pageType: 'competed',
      map: this.map,
      data: this.gridData,
      color: gridColorObj.color,
      weightFiled: 'num',
      weightType: 1,
      rankFiled: 'rank',
      strokeStyle: gridColorObj.strokeStyle,
      lineWidth: gridColorObj.lineWidth,
      textColor: gridColorObj.textColor,
    });

    this.grid.gridClick((item: any) => {
      const { rank } = item;
      this.rowActive = rank;
      this.gridClickInfo(item);
    });
    this.grid.gridMove();
  }

  gridClickInfo(item: any): void{
    if (this.gridLabel) {
      this.gridLabel.setMap(null);
    }
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { type, m_num: mNum, n_num: nNum, m_rank: mRank, n_rank: nRank, center_lat: centerLat,
      center_lng: centerLng } = item;
    const typeText = this.competeTip[type - 1].label;
    const percent1 = Math.round(mNum / nNum * 100) / 100;
    const percent = mNum === 0 || nNum === 0 ? 0 : percent1;
    let content = '';
    const img = `<img class="close-info" style="position:absolute;top:10px;right:10px;cursor:pointer;" src="${this.closePath}">`;
    if (mNum && nNum) {
      content = `<div class="grid-info-tip" style="border:none !important;user-select:none;padding:20px 20px 12px 20px;position:relative;">${img}<div class="grid-title">${typeText}</div><ul><li class="grid-info-item"><span>主场客流排名</span><span>${mRank}</span></li><li class="grid-info-item"><span>主场客流数(a)</span><span>${mNum}</span></li><li class="grid-info-item"><span>竞品排名</span><span>${nRank}</span></li><li class="grid-info-item"><span>竞品客流数(b)</span><span>${nNum}</span></li><li class="grid-info-item"><span>比值(a/b)</span><span>${percent}</span></li></ul></div>`;
    } else if (mNum) {
      content = `<div class="grid-info-tip" style="border:none !important;user-select:none;padding:20px 20px 12px 20px;position:relative;">${img}<div class="grid-title">${typeText}</div><ul><li class="grid-info-item"><span>主场客流排名</span><span>${mRank}</span></li><li class="grid-info-item"><span>主场客流数(a)</span><span>${mNum}</span></li></ul>`;
    } else {
      content = `<div class="grid-info-tip" style="border:none !important;user-select:none;padding:20px 20px 12px 20px;position:relative;">${img}<div class="grid-title">${typeText}</div><ul><li class="grid-info-item"><span>竞品排名</span><span>${nRank}</span></li><li class="grid-info-item"><span>竞品客流数(b)</span><span>${nNum}</span></li></ul>`;
    }
    const center = getMapCenter(`${centerLat},${centerLng}`);
    this.gridLabel = drawLabel(this.map, center, content, 1, 1, this.gridInfoStyle, true);
    if (this.time) {
      clearTimeout(this.time);
    }
    this.time = setTimeout(() => {
      this.$jQ('.close-info').click(() => {
        this.wrapModel = false;
        if (this.gridLabel) {
          this.gridLabel.setMap(null);
        }
        this.$jQ('.grid-info-tip').remove();
      });
    }, 500);
  }

  closeClickHandler(): void{
    this.wrapModel = false;
  }

  buriedPointHandler(buriedPoint: string, params?: any): void{
    this.$beaconAction.onUserAction(buriedPoint, params);
  }

  radioChangeHandler(item: any): void{
    const { value, label } = item;
    this.radioType = value;
    this.radioText = label;
    let data: any = [];
    const stackBarXData: number[] = [];
    const stackBarDataOne: number[] = [];
    const stackBarDataTwo: number[] = [];
    this.buriedPointHandler('competitive_region_menu_select', {
      type: label,
    });
    if (this.radioType === 1) {
      data = this.data;
      this.data.forEach((item: any) => {
        const { m_num: mNum, n_num: nNum, rank } = item;
        stackBarXData.push(rank);
        stackBarDataOne.push(mNum);
        stackBarDataTwo.push(nNum);
      });
    } else {
      this.data.forEach((item: any) => {
        const { type, m_num: mNum, n_num: nNum, rank } = item;
        if (type === this.radioType) {
          data.push(item);
          stackBarXData.push(rank);
          stackBarDataOne.push(mNum);
          stackBarDataTwo.push(nNum);
        }
      });
    }
    this.stackBarXData = stackBarXData;
    this.stackBarDataOne = stackBarDataOne;
    this.stackBarDataTwo = stackBarDataTwo;
    this.$jQ('.grid-info-tip').remove();
    this.gridData = data;
    this.drawGrid(this.gridColorObj);
  }

  @Watch('$store.state.mall.duration', {})
  changeMall1(): void {
    this.gridColorObj = this.gridColorObj1;
    this.gridType = 0;
    this.pageHandlers();
  }

  @Watch('$store.state.mall.date', {})
  changeMall2(): void {
    this.gridColorObj = this.gridColorObj1;
    this.gridType = 0;
    this.pageHandlers();
  }

  @Watch('$store.state.mall.competedModelObj.nMallId')
  changeCompetedModelObj(): void {
    this.gridColorObj = this.gridColorObj1;
    this.gridType = 0;
    this.pageHandlers();
  }

  @Watch('$store.state.global.downloadStatus')
  changeExport(): void {
    const status = this.$store.state.global.downloadStatus;
    if (status === true) {
      this.processExcelData();
      this.$root.$data.event.$emit('getDataS', Object.values(this.allData));
    }
  }

  zoomUp(): void {
    zoomUp(this.map);
  }

  zoomDown(): void {
    zoomDown(this.map);
  }

  mallPostionClickHandler(): void{
    const center = getMapCenter(this.center);
    this.map.panTo(center);
  }
}
