
































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { computeCompareData } from '..';
@Component({
  components: {},
})
export default class CompeteRegionInfo extends Vue {
  @Prop({
    type: Object,
    required: false,
  })
  regionInfo!: any;
  @Prop({
    type: Object,
    required: false,
  })
  regionInfo2!: any;
  @Prop({
    type: String,
    required: false,
  })
  duration!: 'd';
  @Prop({
    type: Number,
    required: false,
  })
  nMallId!: 0;
  compareDuration = '较昨日';
  tipValue = '可查看主场及竞品在竞争区域、竞争客流等维度下的竞争细节';
  radioModal = 1;
  competeInfoStatus = false;
  mInfoStatus = false;
  nInfoStatus = false;
  mNInfoStatus = false;
  mUnInfoStatus = false;
  nUnInfoStatus = false;
  radioList = Vue.prototype.$GLOBAL.competeTip;
  mObj = this.regionInfo.m_obj;
  nObj = this.regionInfo.n_obj;
  mNObj = this.regionInfo.m_n_obj;
  mUnObj = this.regionInfo.m_un_obj;
  nUnObj = this.regionInfo.n_un_obj;
  mPercent = 0;
  nPercent = 0;
  mUnPercent = 0;
  nUnPercent = 0;
  mNPercent = 0;
  isDown = false;
  isUp = false;

  radioChangeHandler(e: number): void {
    const item = this.radioList[e - 1];
    const { value: type } = item;
    this.competeInfoStatus = type !== 1;
    if (type === 2) {
      this.nInfoStatus = false;
      this.mInfoStatus = true;
      this.mNInfoStatus = false;
      this.mUnInfoStatus = false;
      this.nUnInfoStatus = false;
    } else if (type === 3) {
      this.nInfoStatus = true;
      this.mInfoStatus = false;
      this.mNInfoStatus = false;
      this.mUnInfoStatus = false;
      this.nUnInfoStatus = false;
    } else if (type === 4) {
      this.nInfoStatus = false;
      this.mInfoStatus = false;
      this.mNInfoStatus = true;
      this.mUnInfoStatus = false;
      this.nUnInfoStatus = false;
    } else if (type === 5) {
      this.nInfoStatus = false;
      this.mInfoStatus = false;
      this.mNInfoStatus = false;
      this.mUnInfoStatus = true;
      this.nUnInfoStatus = false;
    } else if (type === 6) {
      this.nInfoStatus = false;
      this.mInfoStatus = false;
      this.mNInfoStatus = false;
      this.mUnInfoStatus = false;
      this.nUnInfoStatus = true;
    }
    this.$emit('radioChangeHandler', item);
  }

  tipInfoClickHandler(): void {
    this.$emit('tipInfoClickHandler');
  }

  @Watch('regionInfo', {})
  changeMall1(): void {
    const {
      m_n_obj: mNObj,
      m_obj: mObj,
      n_obj: nObj,
      m_un_obj: mUnObj,
      n_un_obj: nUnObj,
    } = this.regionInfo;
    this.mObj = mObj;
    this.nObj = nObj;
    this.mNObj = mNObj;
    this.mUnObj = mUnObj;
    this.nUnObj = nUnObj;

    const {
      m_n_obj: mNObj2,
      m_obj: mObj2,
      n_obj: nObj2,
      m_un_obj: mUnObj2,
      n_un_obj: nUnObj2,
    } = this.regionInfo2;
    const mArea1 = mObj.area;
    const mArea2 = mObj2.area;

    const nArea1 = nObj.area;
    const nArea2 = nObj2.area;

    const mUnArea1 = mUnObj.area;
    const mUnArea2 = mUnObj2.area;

    const nUnArea1 = nUnObj.area;
    const nUnArea2 = nUnObj2.area;

    const mNArea1 = mNObj.area;
    const mNArea2 = mNObj2.area;

    const mPercent = computeCompareData(mArea1, mArea2);
    const nPercent = computeCompareData(nArea1, nArea2);
    const mUnPercent = computeCompareData(mUnArea1, mUnArea2);
    const nUnPercent = computeCompareData(nUnArea1, nUnArea2);
    const mNPercent = computeCompareData(mNArea1, mNArea2);
    this.mPercent = mPercent;
    this.nPercent = nPercent;
    this.mUnPercent = mUnPercent;
    this.nUnPercent = nUnPercent;
    this.mNPercent = mNPercent;
    if (this.mPercent < 0) {
      this.isDown = true;
      this.isUp = false;
    }
    this.compareDuration = this.$store.state.mall.compareDuration;
    // this.durationText = this.durationTextObj[this.duration];
  }
}
